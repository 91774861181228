import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54979c85"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "referral_link white_text flex align_center content_space_between" }
const _hoisted_2 = { class: "flex align_center referral_content_wrapper" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "flex align_center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.referralLink)
        ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.referralLink), 1))
        : (_openBlock(), _createElementBlock("small", _hoisted_4, " Generate referral Link "))
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.referralLink)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            small: "",
            icon: "content_copy",
            dark: "",
            onClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyLinkToClipboard()))
          }))
        : (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            small: "",
            icon: "new_window",
            dark: "",
            onClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.createLink()))
          }))
    ])
  ]))
}