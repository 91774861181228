<template>
  <div class="referral_stats white_text flex align_center content_space_between">
    <div class="referral_stat_box light_background_trans" v-for="(stat, index) in stats" :key="index">
      <div>
        <Icon 
        :icon="stat.icon" 
        :title="stat.text"
        class="icon slm"  
        color="#e0ded2"
        small 
        />
        <small class="slm">
          {{ stat.text }}
        </small>
      </div>
      <small class="srm">
        {{ stat.value }}
      </small>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Icon from "../Shared/Global/Icon.vue";
import { ReferralStatType } from "@/helpers/types/ReferralTypes"

export default defineComponent({
  name: "ReferralStats",

  components: { 
    Icon,
   },

  data() {
    return {};
  },

  computed: {
    referralUsers(): number {
      return this.$store.getters.getReferralUsers;
    },

    referralTokensEarned(): number {
      return this.$store.getters.getReferralTokensEarned;
    },

    stats(): Array<ReferralStatType> {
      return [
        {
          value: this.referralUsers,
          icon: 'group',
          text: 'Referral Users'
        },
        // {
        //   value: this.referralTokensEarned,
        //   icon: 'token',
        //   text: 'Tokens Earned'
        // },
      ];
    }
  },

  methods: {
   
  },
});
</script>

<style scoped lang="scss">
.referral_stats {
  height: inherit;
  margin-left: 17px;
  width: calc(100% - 34px);
}

.referral_stat_box {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;

  div {
    display: flex;
    align-items: center;
  }
}
</style>
