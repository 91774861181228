/**
 * This code was GENERATED using the solita package.
 * Please DO NOT EDIT THIS FILE, instead rerun solita to update it or write a wrapper to add functionality.
 *
 * See: https://github.com/metaplex-foundation/solita
 */

import * as beet from "@metaplex-foundation/beet";
/**
 * This type is used to derive the {@link Action} type as well as the de/serializer.
 * However don't refer to it in your code but use the {@link Action} type instead.
 *
 * @category userTypes
 * @category enums
 * @category generated
 * @private
 */
export type ActionRecord = {
  Join: { fields: [beet.bignum] };
  Leave: { fields: [beet.bignum] };
};

/**
 * Union type respresenting the Action data enum defined in Rust.
 *
 * NOTE: that it includes a `__kind` property which allows to narrow types in
 * switch/if statements.
 * Additionally `isAction*` type guards are exposed below to narrow to a specific variant.
 *
 * @category userTypes
 * @category enums
 * @category generated
 */
export type Action = beet.DataEnumKeyAsKind<ActionRecord>;

export const isActionJoin = (x: Action): x is Action & { __kind: "Join" } =>
  x.__kind === "Join";
export const isActionLeave = (x: Action): x is Action & { __kind: "Leave" } =>
  x.__kind === "Leave";

/**
 * @category userTypes
 * @category generated
 */
export const actionBeet = beet.dataEnum<ActionRecord>([
  [
    "Join",
    new beet.BeetArgsStruct<ActionRecord["Join"]>(
      [["fields", beet.fixedSizeTuple([beet.u64])]],
      'ActionRecord["Join"]',
    ),
  ],
  [
    "Leave",
    new beet.BeetArgsStruct<ActionRecord["Leave"]>(
      [["fields", beet.fixedSizeTuple([beet.u64])]],
      'ActionRecord["Leave"]',
    ),
  ],
]) as beet.FixableBeet<Action, Action>;
