import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-526f77ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user_name white_text flex align_center content_space_between" }
const _hoisted_2 = { class: "flex align_center" }
const _hoisted_3 = { class: "flex align_center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_BaseInput = _resolveComponent("BaseInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isEditable)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            pointer: false,
            small: "",
            icon: "account_circle",
            color: "#e0ded2"
          }))
        : (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            small: "",
            icon: "cancel",
            color: "#e0ded2",
            onClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
          })),
      _createElementVNode("form", {
        ref: "chatForm",
        class: "h100",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.submitName()), ["prevent"]))
      }, [
        _createVNode(_component_BaseInput, {
          readonly: !_ctx.isEditable,
          ref: "input",
          onValueUpdated: _ctx.valueUpdated
        }, null, 8, ["readonly", "onValueUpdated"])
      ], 544)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.isEditable)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            small: "",
            icon: "edit",
            dark: "",
            onClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleEdit()))
          }))
        : (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            small: "",
            icon: "send",
            dark: "",
            onClicked: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submitName()))
          }))
    ])
  ]))
}