<template>
  <div class="referral_link white_text flex align_center content_space_between">
    <div class="flex align_center referral_content_wrapper">
      <small v-if="referralLink">
        {{ referralLink }}
      </small>
      <small v-else>
        Generate referral Link
      </small>
    </div>
    <div class="flex align_center">
      <Icon v-if="referralLink" small icon="content_copy" dark @clicked="copyLinkToClipboard()" />
      <Icon v-else small icon="new_window" dark @clicked="createLink()" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Icon from "../Shared/Global/Icon.vue";

export default defineComponent({
  name: "ReferralLink",

  components: { 
    Icon,
   },

  data() {
    return {};
  },

  computed: {
    referralLink(): string | null {
      return this.$store.getters.getReferralLink;
    }
  },

  methods: {
    createLink() {
      this.$store.dispatch('generateReferralLink');
    },
    
    copyLinkToClipboard() {
      if(!this.referralLink) {
        return;
      }

      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(this.referralLink);
        return;
      }
      navigator.clipboard.writeText(this.referralLink).then(() => {
        this.$store.dispatch('addInfoNotification', "Copying to clipboard was successful")
      }, function(err) {
        console.error('Could not copy text: ', err);
      });
    },

    fallbackCopyTextToClipboard(text: string) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      
      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if(successful) {
          this.$store.dispatch('addInfoNotification', "Copying to clipboard was successful")
        } else {
          throw new Error;
        }
      } catch (err) {
        console.error('Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    }
  },
});
</script>

<style scoped lang="scss">
.referral_link {
  width: calc(100% - 24px);
  height: 100%;
  border-radius: 22px;
  padding: 0px 12px;
}

.referral_content_wrapper {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: .9em;
}
</style>
