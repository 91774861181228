import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ff16955"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["material-symbols-outlined icon", {
      'pointer': _ctx.pointer,
      'dark_text': !_ctx.dark,
      'light_text': _ctx.dark,
      'small_icon': _ctx.small,
      'medium_icon': _ctx.medium || (!_ctx.small && !_ctx.large),
      'large_icon': _ctx.large,
    }]),
    title: _ctx.title,
    style: _normalizeStyle(_ctx.color ? `color: ${_ctx.color} !important` : ''),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clicked()))
  }, _toDisplayString(_ctx.icon), 15, _hoisted_1))
}