import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cbc435e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: _ctx.logoSrc,
    alt: "Logo",
    class: "h100 w100 pointer",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.submit()))
  }, null, 8, _hoisted_1))
}