import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-564e7822"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "referral_stats white_text flex align_center content_space_between" }
const _hoisted_2 = { class: "slm" }
const _hoisted_3 = { class: "srm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stats, (stat, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "referral_stat_box light_background_trans",
        key: index
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_Icon, {
            icon: stat.icon,
            title: stat.text,
            class: "icon slm",
            color: "#e0ded2",
            small: ""
          }, null, 8, ["icon", "title"]),
          _createElementVNode("small", _hoisted_2, _toDisplayString(stat.text), 1)
        ]),
        _createElementVNode("small", _hoisted_3, _toDisplayString(stat.value), 1)
      ]))
    }), 128))
  ]))
}