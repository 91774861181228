import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19fbf842"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "desktop_toolbar flex align_center dark_background" }
const _hoisted_2 = { class: "menu_wrapper flex align_center content_space_between" }
const _hoisted_3 = { class: "logo_wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuIcon = _resolveComponent("MenuIcon")!
  const _component_LogoutButton = _resolveComponent("LogoutButton")!
  const _component_ConnectButton = _resolveComponent("ConnectButton")!
  const _component_AppLogo = _resolveComponent("AppLogo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MenuIcon),
      (_ctx.$store.getters.isLoggedIn)
        ? (_openBlock(), _createBlock(_component_LogoutButton, {
            key: 0,
            onClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
          }))
        : (_openBlock(), _createBlock(_component_ConnectButton, {
            key: 1,
            onClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.connect()))
          }))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AppLogo, {
        onSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/')))
      })
    ])
  ]))
}