import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-006ccb1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  open: "",
  class: "game_deposit_dialog mp"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "user_name light_background_trans white_text flex align_center content_space_between" }
const _hoisted_4 = { class: "flex align_center w100" }
const _hoisted_5 = { class: "flex align_center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_BaseInput = _resolveComponent("BaseInput")!

  return (_openBlock(), _createElementBlock("dialog", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_Icon, {
        small: "",
        icon: "close",
        dark: "",
        onClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
      })
    ]),
    _createElementVNode("p", {
      innerHTML: _ctx.description,
      class: "white_text"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("form", {
          ref: "chatForm",
          class: "h100 w100",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.submitDepositPrice()), ["prevent"]))
        }, [
          _createVNode(_component_BaseInput, {
            type: "number",
            ref: "input",
            onValueUpdated: _ctx.valueUpdated
          }, null, 8, ["onValueUpdated"])
        ], 544)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Icon, {
          small: "",
          icon: "send",
          dark: "",
          onClicked: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitDepositPrice()))
        })
      ])
    ])
  ]))
}